<template>
    <section>
  
      <b-card border-variant="danger" v-if="errore_caricamento">
          <b-row class="match-height">
          <b-col
              md="8"
          >
              <h4 class="mt-0 mb-0 text-danger">Errore durante il caricamento delle informazioni iniziali</h4>
              <p>Ti preghiamo di segnalare il problema all'assistenza tecnica, grazie</p>
          </b-col>
          <b-col 
              md="4"
              class="text-center"
          >
              <b-button
              variant="primary"
              :to="{ name: 'home-agentscout' }"
              >
                  <feather-icon
                      icon="CornerUpLeftIcon"
                      size="16"
                      class="mr-1"
                  />Home
              </b-button>
          </b-col>
          </b-row>
          
      </b-card>
      
      <div class="text-center my-3" v-if="Caricato == false">
          <b-spinner
              variant="primary"
              style="width: 3rem; height: 3rem;"
              class="mr-1"
              label="loading"
          />
      </div>
  
      <div v-if="Caricato">

        <b-tabs pills>
          <b-tab active>
            <template #title>
                <feather-icon
                icon="UserIcon"
                size="16"
                class="mr-0 mr-sm-50"
                />
                <span class="d-none d-sm-inline">Dati utente</span>
            </template>

            <b-card bg-variant="Default">
              <b-card-body class="px-0 py-0">
                <b-row>
                  <b-col md="4">
                    <showdata field_name="Nome" :field_value="campiform.user.name" />
                  </b-col>
                  <b-col md="4">
                    <showdata field_name="Cognome" :field_value="campiform.user.surname" />
                  </b-col>

                  <b-col md="4">
                    <showdata field_name="Email" :field_value="campiform.user.email" />
                  </b-col>
                  <b-col md="4">
                    <showdata field_name="Telefono personale" :field_value="campiform.user.phone" />
                  </b-col>
                  <b-col md="4">
                    <showdata field_name="Cellulare" :field_value="campiform.user.mobile_phone" />
                  </b-col>
                  <b-col md="4">
                    <showdata field_name="Sesso" :field_value="campiform.user.sesso_name" />
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-tab>

          <b-tab>
            <template #title>
                <feather-icon
                icon="MailIcon"
                size="16"
                class="mr-0 mr-sm-50"
                />
                <span class="d-none d-sm-inline">Firma email</span>
            </template>
            <b-card bg-variant="Default">
              <b-card-body class="px-0 py-0">
                <div class="bordo-email" v-html="this.firmaemail.firma"></div>
              </b-card-body>

            </b-card>
          </b-tab>

          <b-tab>
            <template #title>
                <feather-icon
                icon="MailIcon"
                size="16"
                class="mr-0 mr-sm-50"
                />
                <span class="d-none d-sm-inline">Google Workspace</span>
            </template>
            <b-card bg-variant="Default">
              <b-card-body class="px-0 py-0">
                <card-check-google
                  :id_utente.sync = "id_utente_loggato"
                  :api_token_google.sync = "api_token_google"
                  :loading_google = "loading_google"
                  :errore_caricamento.sync = "api_token_google_errore_caricamento"
                />

              </b-card-body>

            </b-card>
          </b-tab>

        </b-tabs>

      </div>
        
    </section>
  </template>

<script>
import { BTabs, BTab, BRow, BCol, BCard, BCardTitle, BCardHeader, BCardBody, BCardText, BSpinner, BButton, BDropdownDivider } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import showdata from '@core/components/showdata/showdata.vue'
import CardCheckGoogle from './CardCheckGoogle'

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard, 
    BCardTitle, 
    BCardHeader, 
    BCardBody, 
    BCardText, 
    BSpinner, 
    BButton, 
    BDropdownDivider,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent, 

    showdata, 
    CardCheckGoogle,

  },
  data() {
    return {
      userData: {},

      campiform: {
        user: { },
      },
      Caricato: false,
      errore_caricamento: false,

      id_utente_loggato: '',
      api_token_google: [],
      loading_google: true,
      api_token_google_errore_caricamento: false,

      firmaemail: {
        firma: '',
        user_id: '',
      },

    }
  },
  created() {
    // dati reali
    this.userData = JSON.parse(localStorage.getItem('userData'))
    //console.log(this.userData)

    const genderPromise = this.$http.get('api/fs/gender/list')
    const infoUserProfilePromise = this.$http.get('api/crm/user/singolacard/'+this.userData.id)

    Promise.all([genderPromise, infoUserProfilePromise]).then(response => {
      if(response[0].data.statusCode===200){

          if(response[1].data.statusCode===200){
            //form dati employee (+ user)
            this.campiform.user = response[1].data.reply.data

            //console.log(this.campiform.user)

            //sesso
            var id_sesso = this.campiform.user.gender_type_id
            var sesso = response[0].data.reply.filter(function(elem){
                if(elem.value == id_sesso){
                  return elem.text
                }
            })
            this.campiform.user.sesso_name = sesso[0].text

            this.$http.get('api/crm/emailsignature/emailuser/'+this.userData.id).then(emailsignature => {
              if(emailsignature.data.statusCode===200){
                //console.log(emailsignature.data.reply.data)

                if(emailsignature.data.reply.data === null){
                  //firma non presente
                  this.firmaemail.firma = 'Firma email al momento non configurata';
                } else {
                  //firma trovata
                  this.firmaemail.firma = emailsignature.data.reply.data.firma
                  this.firmaemail.id = emailsignature.data.reply.data.id
                }
              } else {
                this.$swal({
                  icon: 'warning',
                  title: 'Errore USF 12',
                  text: 'Non è stato possibile recuperare i dati della firma, prego assisare il nostro reparto tecnico',
                  customClass: {
                    confirmButton: 'btn btn-outline-primary',
                  },
                })
              }
            }).catch(e => {
              console.log(e);
            });

            //fine
            this.Caricato = true;
            this.errore_caricamento = false;

          } else {
            this.Caricato = false;
            this.errore_caricamento = true;
          }
      } else {
        this.Caricato = false;
        this.errore_caricamento = true;
      }
    })

    this.id_utente_loggato = this.userData.id
    this.checkTokenApiGoogle(this.id_utente_loggato)
    
  },
  methods: {
    sidebarActionShow(id, user_id, titolo) {
      this.infoSideBar = { id: id, user_id: user_id, titolo: titolo };

      this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
    },
    checkTokenApiGoogle(id_employee){
      this.$http.get('api/crm/google/oauth/checktoken/'+id_employee).then(response => {
        if(response.data.statusCode === 200){
          //ok dati ricevuti
          this.api_token_google = response.data.reply.data;
          //console.log(response.data.reply.data)

          this.loading_google = false
          this.api_token_google_errore_caricamento =  false;
        } else {
          //errore da api
          this.loading_google = false
          this.api_token_google_errore_caricamento = true;
        }
      
      }).catch(e => {
        this.loading_google = false
        this.api_token_google_errore_caricamento = true;
      }); 
    },

  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';

.bordo-email{
  border: 1px solid #eee;
  border-radius: 10px;
  padding: 15px;
}
.bordo-email p{
  margin-bottom: 0;
}
</style>
